<script>
import axios from "axios";

export default {
  name: "ViewSMGS",
  data() {
    return {
      showModal: false,
      image_path: null,
      converted_smgs_url: null
    }
  },
  methods: {
    openModal(image_path) {
      if (image_path) {
        this.image_path = image_path
        if (this.image_path.endsWith('.pdf')) {
          this.setSMGSPDF(this.image_path)
        } else {
          this.convertToPdf()
        }
        this.showModal = true
      } else {
        alert("Invalid SMGS")
      }
    },
    async setSMGSPDF(url) {
      try {
        await axios.get(url, {
          responseType: 'arraybuffer',
        }).then(response => {
          const blob = new Blob([response.data], {type: 'application/pdf'});
          this.converted_smgs_url = URL.createObjectURL(blob);
        }).catch(error => {
          console.log("error", error)
          return ''
        });
      } catch {
        alert("Couldn't get SMGS PDF")
      }
    },
    async convertToPdf() {
      try {
        await axios.get('/smgs/convert/', {
          responseType: 'arraybuffer',
          params: {
            image_urls: this.image_path,
          }
        }).then(response => {
          const blob = new Blob([response.data], {type: 'application/pdf'});
          this.converted_smgs_url = URL.createObjectURL(blob);
        }).catch(error => {
          console.log("error", error)
          return ''
        });

      } catch {
        alert("Couldn't convert SMGS to PDF")
      }
    },
    downloadFile() {
      let url = this.converted_smgs_url;
      this.is_downloading_file = true
      fetch(url)
          .then(resp => resp.blob())
          .then(blobobject => {
            const blob = window.URL.createObjectURL(blobobject);
            const anchor = document.createElement('a');
            anchor.style.display = 'none';
            anchor.href = blob;
            anchor.download = url.toString().split('/')[url.toString().split('/').length - 1];
            document.body.appendChild(anchor);
            anchor.click();
            window.URL.revokeObjectURL(blob);
            this.is_downloading_file = false
          })
          .catch(() => alert('An error in downloading the file sorry'));
    }
  }
}
</script>

<template>
  <b-modal v-model="showModal" title="SMGS"
           hide-footer centered size="xl">
    <div v-if="converted_smgs_url">
      <iframe
          :src="`${converted_smgs_url}`" width="100%"
          height="900px"></iframe>
      <div class="mt-3">
        <b-button
            @click="downloadFile(converted_smgs_url)"
            variant="primary">
          Download
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<style scoped>

</style>