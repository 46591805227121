<script>
import Table from "@/components/tables/table.vue";
import ViewSMGSModal from "@/views/pages/trains/details/modals/ViewSMGS.vue";

export default {
  name: "TrainSMGS_Tab",
  components: {
    Table,
    ViewSMGSModal
  },
  data() {
    return {
      table: {
        name: `SMGS list for ${this.$route.params.train_slug}`,
        headers: [
          {
            field: 'container_name',
            label: 'Container number',
            align: 'center'
          },
          {
            field: 'wagon',
            label: 'Wagon number',
            align: 'center'
          },
          {
            field: 'number',
            label: 'SMGS number',
            align: 'center'
          },
          {
            field: 'image_path',
            label: 'SMGS',
            align: 'center',
            searchable: false
          },
          {
            field: 'weight',
            label: 'Weight',
            align: 'center'
          },
          {
            field: 'departure_station',
            label: 'Departure station',
            align: 'center'
          },
          {
            field: 'destination_station',
            label: 'Destination station',
            align: 'center'
          },
          {
            field: 'consignee',
            label: 'Consignee',
            align: 'center'
          },
          {
            field: 'shipper',
            label: 'shipper',
            align: 'center'
          },
          {
            field: 'date',
            label: 'Date',
            align: 'center',
            searchable: false
          }
        ],
        url: `/smgs/list/by/${this.$route.params.train_slug}/`,
        selectable: true,
        searchable: true
      }
    }
  },
  methods: {
    async openViewSMGSModal(path) {
      if (!path) return;
      this.$refs.viewSMGSModal.openModal(process.env.VUE_APP_ORDER_URL + '/' + path)
    },
  }
}
</script>

<template>
  <ViewSMGSModal ref="viewSMGSModal"/>
  <Table v-bind="table" class="mb-4">
    <template v-slot:wagon="{row: data}">
      {{ data.wagon ? data.wagon.name : '' }}
    </template>
    <template v-slot:image_path="{row: data}">
      <i v-b-tooltip.hover :title="data.image_path.split('/').pop()"
         @click="openViewSMGSModal(data.image_path)"
         v-if="data.image_path" class="bx bxs-file-blank text-primary fs-5 align-middle"></i>
    </template>
    <template v-slot:weight="{row: data}">
      {{ data.weight || 0 }}
    </template>
    <template v-slot:departure_station="{row: data}">
      {{ data.departure_station ? data.departure_station.name : '' }}
    </template>
    <template v-slot:destination_station="{row: data}">
      {{ data.destination_station ? data.destination_station.name : '' }}
    </template>
    <template v-slot:consignee="{row: data}">
      {{ data.consignee ? data.consignee.name : '' }}
    </template>
    <template v-slot:shipper="{row: data}">
      {{ data.shipper ? data.shipper.name : '' }}
    </template>
  </Table>
</template>

<style scoped>

</style>